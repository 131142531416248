import React from "react";
import profilePicture from "./assets/profile-picture.jpg";
import "./About.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin, faGoogleScholar } from '@fortawesome/free-brands-svg-icons';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';

function About() {
  return (
    <div className="container">
      <div className="profile-container">
        {/* Left Column - Profile Info */}
        <div className="profile-sidebar">
          <img 
            src={profilePicture} 
            alt="Yazan Mash'Al" 
            className="profile-image"
          />

        <div className="profile-info">
          <h1>Yazan Mash'Al</h1>
          <h2>MSc in Applied Mathematics</h2>
          <div className="institution-info">
            <a 
              href="https://www.tudelft.nl/" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              TU Delft
            </a>
          </div>
        </div>
          

        <div className="social-links">
            <a href="https://github.com/yazanmashal03" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faGithub} className="social-icon" />
            </a>
            <a href="https://www.linkedin.com/in/yazan-mash-al-236a88220/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} className="social-icon" />
            </a>
            {/* Add Twitter/X link here */}
            <a href="https://twitter.com/yazanmashal03" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faXTwitter} className="social-icon" />
            </a>
            <a href="mailto:y.mashal@student.tudelft.nl">
              <FontAwesomeIcon icon={faGoogleScholar} className="social-icon" />
            </a>
          </div>
        </div>

        {/* Right Column - Main Content */}
        <div className="profile-content">
          <div className="bio-section">
            <p>
              I am a <strong>MSc student in Applied Mathematics</strong> at TU Delft. 
              I am an aspiring scientist with the hopes of joining a PhD program after finishing my MSc. 
              My research interests revolve around NLP and AI/ML. Besides technology, I'm also interested 
              in finance & economy, fitness (running) & health, psychology, and philosophy. I enjoy reading 
              anything and everything.
            </p>
          </div>

          {/* Combined Sections Container */}
          <div className="sections-container">
            {/* Interests Section */}
            <div className="interests-section">
              <h3>Research Interests</h3>
              <ul>
                <li><FontAwesomeIcon icon={faBook} /> Improving SOTA in ML</li>
                <li><FontAwesomeIcon icon={faBook} /> NLP Applications</li>
                <li><FontAwesomeIcon icon={faBook} /> Privacy & Security in ML Systems</li>
              </ul>
            </div>

            {/* Education Section */}
            <div className="education-section">
              <h3>Academic Background</h3>
              <div className="education-item">
                <FontAwesomeIcon icon={faGraduationCap} className="edu-icon" />
                <div className="edu-details">
                  <h4>Ph.D. in Machine Learning (Expected 2026)</h4>
                </div>
              </div>
              <div className="education-item">
                <FontAwesomeIcon icon={faGraduationCap} className="edu-icon" />
                <div className="edu-details">
                  <h4>MSc Applied Mathematics (2024-Ongoing)</h4>
                  <p>Technical University of Delft, Netherlands</p>
                </div>
              </div>
              <div className="education-item">
                <FontAwesomeIcon icon={faGraduationCap} className="edu-icon" />
                <div className="edu-details">
                  <h4>Double BSc in Mathematics & Computer Science (2021-2024)</h4>
                  <p>University of Twente, Netherlands</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;