import React from 'react';
import ProjectCard from './ProjectCard';
import './ProjectList.css';
import querio from "./assets/querio.jpg";
import mids from "./assets/mids.jpg";
import first from "./assets/first-principles.png";

function ProjectList() {
  const projects = [
    {
      title: "Implemented machine learning algorithms from scratch",
      description: "This repository is an inspiration from https://github.com/eriklindernoren/ML-From-Scratch, but the implementations are my own.",
      imageUrl: first,
      tags: ["machine learning", "tensorflow", "numpy", "pandas"],
      backgroundColor: "gray",
      link: "https://github.com/yazanmashal03/ml_from_scratch"
    },
    {
      title: "Querio",
      description: "Developed in collaboration with NextSelect specific features that facilitate the day-to-day operations of imec, one of Europe's leading chip manufacturing companies.",
      imageUrl: querio,
      tags: ["PHP", "MYSQL", "CSS", "Docker", "HTML"],
      backgroundColor: "gray",
      link: "https://querio.imec.be/"
    },
    {
        title: "MIDS",
        description: "Developed in collaboration with NextSelect specific features that facilitate the day-to-day operations of MASER, one of Europe's leading chip testing companies.",
        imageUrl: mids,
        tags: ["PHP", "MYSQL", "CSS", "Docker", "HTML"],
        backgroundColor: "gray", // make it green
        link: "https://mids.maser.nl/"
    }
  ];

  return (
    <div className="project-list">
      {projects.map((project, index) => (
        <ProjectCard 
          key={index} 
          title={project.title}
          description={project.description}
          imageUrl={project.imageUrl}
          tags={project.tags}
          backgroundColor={project.backgroundColor}
          link={project.link}
        />
      ))}
    </div>
  );
}

export default ProjectList;
