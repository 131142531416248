import React, { useState } from 'react';
import './Research.css'; // Optional for your custom styling

const Research = () => {
  const [activeTab, setActiveTab] = useState('bachelor');

  const researchTabs = {
    bachelor: [
      {
        title: "Gradient Descent with Random Minibatches",
        description: "This thesis explores the dynamics and convergence properties of gradient descent algorithms when integrated with random minibatches.",
        tags: ["Grade: 9/10", "Bachelor Thesis"],
        supervisors: [
          { name: "Prof. Johannes Schmidt-Hieber", link: "https://jschmidthieber.personalweb.utwente.nl/#" },
          { name: "Dr. Gabriel Clara", link: "https://gclara.gitlab.io/" }
        ],
        paperLink: "https://essay.utwente.nl/100665/",
        codeLink: "https://github.com/yazanmashal03/projects/tree/main/bachelor_thesis/src",
      },
      {
        title: "Developing a Parity Game GUI",
        description: "Designing and developing a GUI to display and solve parity games.",
        tags: ["Grade: 8/10", "Design Project"],
        paperLink: "https://bachelorshowcase-eemcs.apps.utwente.nl/view/4XMBs3wv",
        codeLink: "https://github.com/MikiVanousek/parity-games",
        supervisors: [
          { name: "Prof. Tom van Dijk", link: "https://www.tvandijk.nl/" }
        ]
      },
      {
        title: "Internship for imec Inc. in Leuven",
        description: "The internship project for imec revolved around designing and developing a stock management system for the nanotechnology company.",
        tags: ["Grade: 9/10", "Internship"],
        paperLink: "https://drive.google.com/file/d/1pxxkhMFYWGncaZM9RqVzO3sQVQDbM8vP/view",
        codeLink: "",
        supervisors: [
          { name: "Alex van Oostrum", link: "https://www.linkedin.com/in/alex-van-oostrum-01860722/?originalSubdomain=nl" }
        ]
      },
      {
        title: "Statistical Analysis of the Poverty Index",
        description: "Leveraged statistical tools to draw important conclusions from the Poverty Index provided by the United Nations Data website.",
        tags: ["Grade: 9.5/10", "Best Grade", "R"],
        paperLink: "https://drive.google.com/file/d/1hdskYpqbOcW-UjXvUKOZeBsUe7nqk_XS/view",
        codeLink: "",
      },
      {
        title: "Modeling the Waters of Walsoorden",
        description: "Modeled the water levels of the Walsoorden area.",
        tags: ["Grade: 7.5/10", "Python"],
        paperLink: "https://drive.google.com/file/d/1TQw9C-T4BvVM6TWpMmMROBagTlJeUqRB/view",
        codeLink: "",
      }
    ],
    nlp: [
      {
        title: "Charactersing toxicity in Generative Large Language Models",
        description: "This paper explores the linguistic factors that might cause generative models to output toxic content. We aim to identify how prone large language models (LLMs) are to generate toxic content when prompted, along with the lexical and syntactic structures that trigger toxicity in the predefined LLMs.",
        tags: ["Grade: 9/10", "NLP", "Research Paper"],
        paperLink: "https://drive.google.com/file/d/1aD4txp7KNLjEfj0frm9kXpeo4sIBfiJt/view?usp=sharing",
        codeLink: "https://github.com/yazanmashal03/nlp-project",
      },
    ],
    applied_image_processing: [
      { 
        title: "Style transfer for image processing", 
        description: "In this report, we outline our Style Transfer Project, detailing the implementation of features, contributions, and results for the Applied Image Processing course 2024/2025.",
        tags: ["Computer Vision", "Python"],
        paperLink: "https://drive.google.com/file/d/1c6DutNdOe7_ifEenEBA9bR5JigKbgQ_9/view?usp=sharing",
        codeLink: "https://drive.google.com/file/d/1GnIklHuFMKDmHaqtNKw9ipK36vkBU77t/view?usp=sharing",
      }
    ]
  };

  const tabLabels = {
    bachelor: "Undergraduate",
    nlp: "Natural Language Processing",
    applied_image_processing: "Applied Image Processing",
  };

  return (
    <div className="research-container">
      <h1>Research</h1>

      {/* Tab Buttons */}
      <div className="sub-tab-buttons">
        {Object.keys(researchTabs).map((tabKey) => (
          <button
            key={tabKey}
            className={activeTab === tabKey ? 'active' : ''}
            onClick={() => setActiveTab(tabKey)}
          >
            {/* Use the custom label if it exists; fallback to the raw key if not */}
            {tabLabels[tabKey] || tabKey}
          </button>
        ))}
      </div>

      {/* Tab Content */}
      <div className="sub-tab-content">
        {researchTabs[activeTab].map((researchItem, index) => (
          <div key={index} className="research-item">
            <h3>{researchItem.title}</h3>
            <p>{researchItem.description}</p>

            {/* Tags */}
            {researchItem.tags && researchItem.tags.length > 0 && (
              <p>
                <strong>Tags:</strong> {researchItem.tags.join(', ')}
              </p>
            )}

            {/* Supervisors */}
            {researchItem.supervisors?.length > 0 && (
              <p className="supervisors">
                <strong>Supervised by:</strong>{" "}
                {researchItem.supervisors.map((supervisor, i) => (
                  <span key={supervisor.link}>
                    <a
                      href={supervisor.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="supervisor-link"
                    >
                      {supervisor.name}
                    </a>
                    {i < researchItem.supervisors.length - 1 ? ", " : ""}
                  </span>
                ))}
              </p>
            )}

            {/* Paper / Code Links */}
            <p>
              {researchItem.paperLink && (
                <a
                  href={researchItem.paperLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginRight: '10px' }}
                >
                  View Paper
                </a>
              )}
              {researchItem.codeLink && (
                <a
                  href={researchItem.codeLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Code
                </a>
              )}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Research;